/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Pedro Navarrete's Portfolio",
  description:
    "A computer science graduate student with experience in full-stack web development and a passion for creating intuitive and scalable software solutions.",
  og: {
    title: "Pedro Navarrete's Portfolio",
    type: "website",
    url: "https://navarrete.dev/",
  },
};

//Home Page
const greeting = {
  title: "Pedro Navarrete",
  logo_name: "Pedro",
  nickname: "Software Engineer",
  subTitle:
    "A software engineer with experience in full-stack web development and a passion for creating intuitive and scalable software solutions.",
  resumeLink: "https://navarrete.dev/public_resume.pdf",
  portfolio_repository: "https://github.com/PedroNavHur",
  githubProfile: "https://github.com/PedroNavHur",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  {
    name: "Github",
    link: "https://github.com/PedroNavHur",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/pedronav/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:navhpedro@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Responsive websites using Javascript, Typescript, NextJS, ReactJS, and Redux.",
        "⚡ Backends in Node with Express and Python with Django.",
        "⚡ Maintaining data in MySQL, MongoDB, and ElasticSearch",
      ],
      softwareSkills: [
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "skill-icons:javascript",
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "skill-icons:typescript",
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "skill-icons:python-dark",
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "skill-icons:nodejs-dark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "skill-icons:django",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "skill-icons:nextjs-dark",
          style: {
            color: "#000",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "skill-icons:react-dark",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "skill-icons:mysql-dark",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "skill-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "ElasticSearch",
          fontAwesomeClassname: "logos:elasticsearch",
        },
      ],
    },
    {
      title: "Game Development",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Games in Unity with C# and AI agents with NumPy.",
        "⚡ Deploy AI agents and Game APIs over AWS lambda functions.",
      ],
      softwareSkills: [
        {
          skillName: "C#",
          fontAwesomeClassname: "logos:c-sharp",
        },
        {
          skillName: "Numpy",
          fontAwesomeClassname: "logos:numpy",
        },
        {
          skillName: "Unity",
          fontAwesomeClassname: "skill-icons:unity-dark",
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "skill-icons:aws-dark",
        },
      ],
    },
    {
      title: "Data Mining & Modeling",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Research in NLP projects.",
        "⚡ Graduate level projects in Artificial intelligence, Machine Learning, Data Clustering, and Data Mining.",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "skill-icons:python-dark",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "skill-icons:tensorflow-dark",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "devicon:keras",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Pytorch",
          fontAwesomeClassname: "skill-icons:pytorch-dark",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [],
};

const degrees = {
  degrees: [
    {
      title: "University of Central Arkansas",
      subtitle: "M.S. in Computer Science",
      logo_path: "uca_logo.png",
      alt_name: "UCA",
      duration: "2022 - 2024",
      descriptions: [
        "⚡ I have taken courses on Artificial Intelligence, Machine Learning, Data Mining, Data Clustering, and Computational Theory.",
        "⚡ Apart from this, I'm currently pursuing research in Natural Language Processing and Computer Vision.",
      ],
      website_link: "https://uca.edu",
    },
    {
      title: "Harding University",
      subtitle: "B.S. in Computer Science",
      logo_path: "harding_logo2.png",
      alt_name: "Harding",
      duration: "2017 - 2021",
      descriptions: [
        "⚡ Graduated with a Honors Summa Cum Laude degree",
        "⚡ Took a variety of courses in Software Engineering, including Data Structures & Algorithms, Data Bases, Operative Systems, and File Structures.",
        "⚡ Pursued a minors in mathematics and took graduate level math courses including Numerical Methods, and Applied Algorithms.",
      ],
      website_link: "https://www.harding.edu/",
    },
  ],
};

const certifications = {
  certifications: [],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - Aug 2021",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "San Francisco, USA",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Here are a few of my hobby projects, if you can't tell, yes, I love board games.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Awards",
  description:
    "Some awards I've got from competitive programming, academics, and research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Floyd Tesmer/Strayer University Prize",
      createdAt: "2021",
      description:
        "Award for the best undergraduate research presentation in the sciences at the Alpha Chi National Convention.",
      url:
        "https://magazine.harding.edu/2021/11/newsmakers-5.html",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Honor Graduate",
      createdAt: "2021",
      description:
        "Summa Cum Laude Computer Science Honor Graduate at Harding University.",
      url: "",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "2nd place on-site ICPC NAC Regionals",
      createdAt: "2019",
      description:
        "My team won the second place at the ICPC regionals competitive programming competition in Forth Smith AR.",
      url: "",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "1st place on-site CCSC Mid South",
      createdAt: "2019",
      description:
        "My team won the first place at the CCSC Mid-south competitive programming competition.",
      url: "",
    },
  ],
};

// Contact Page
const contactPageData = {
  // contactSection: {
  //   title: "Contact Me",
  //   profile_image_path: "animated_ashutosh.png",
  //   description:
  //     "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  // },
  // blogSection: {
  //   title: "Blogs",
  //   subtitle:
  //     "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
  //   link: "https://blogs.ashutoshhathidara.com/",
  //   avatar_image_path: "blogs_image.svg",
  // },
  // addressSection: {
  //   title: "Address",
  //   subtitle:
  //     "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
  //   avatar_image_path: "address_image.svg",
  //   location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  // },
  // phoneSection: {
  //   title: "",
  //   subtitle: "",
  // },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};

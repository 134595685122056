import React, { Component } from "react";

export default class CloudInfraImg extends Component {
  render() {
    // const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="883.27231"
        height="604.19846"
        viewBox="0 0 883.27231 604.19846"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M916.66157,400.17675q18.262,19.15248,40.74882,6.11232c-6.2909-10.08162-7.78872-21.04753-6.11232-32.59906H922.09474C923.3887,383.841,923.04068,393.29284,916.66157,400.17675Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#ffb8b8"
        />
        <polygon
          points="806.517 586.416 822.138 578.267 828.929 492.015 828.929 446.512 792.934 445.833 803.121 491.336 806.517 586.416"
          fill="#ffb8b8"
        />
        <polygon
          points="761.238 586.416 745.618 578.267 738.827 492.015 738.827 446.512 774.821 445.833 764.634 491.336 761.238 586.416"
          fill="#ffb8b8"
        />
        <path
          d="M895.608,603.92088l38.03223-3.39574,6.11233-34.6365,7.47062,33.95736,41.428-2.71659c.90506-16.50188-1.39386-33.48584-6.79147-50.936L890.854,544.15593Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <path
          d="M856.07793,473.84185l-45.74858-35.22817a6.65389,6.65389,0,0,0-1.552-.89132l-6.64947-2.7054c-5.34071-2.17292-15.51341-9.40023-10.55208-16.84222,4-6,19.31121,4.74847,21.19478,8.7305a6.57816,6.57816,0,0,0,2.78936,2.95063l9.297,5.09461a6.6543,6.6543,0,0,0,.69815.3314l35.39618,14.34687a6.65385,6.65385,0,0,0,6.06255-.54712l6.41727-4.069a6.65387,6.65387,0,0,1,10.09008,4.32622l2.12091,10.70422a6.65386,6.65386,0,0,1-4.15709,7.51076l-18.97747,7.23354A6.65387,6.65387,0,0,1,856.07793,473.84185Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#ffb8b8"
        />
        <path
          d="M864.57577,448.17474c12.66636-3.39651,11.3365,14.299,21,23l19-15-11.0052-53.2814h0a19.80313,19.80313,0,0,0-13.04935,11.94925Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#f9a826"
        />
        <path
          d="M888.13739,547.55167h95.75974c-15.49779-41.76348-13.989-84.71695-.79323-128.48821a3.26278,3.26278,0,0,0-1.60367-3.84011L954.01466,400.8559c-10.78934,7.02043-22.38516,4.863-34.6365-4.754l-29.88248,8.14976C897.63053,453.10851,897.86158,500.962,888.13739,547.55167Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#f9a826"
        />
        <path
          d="M912.082,750.26821l-39.90689,1.6916a7.045,7.045,0,0,1-7.26582-5.99632h0a7.045,7.045,0,0,1,5.29021-7.8848l6.76878-1.65919,26.60383-15.4201c3.94138,4.0814,9.36876,7.53242,16.00366,10.47124l.07763,10.84163A7.90645,7.90645,0,0,1,912.082,750.26821Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <polygon
          points="882.877 604.198 0.321 604.198 0.321 602.017 883.272 602.017 882.877 604.198"
          fill="#3f3d56"
        />
        <path
          d="M972.06954,749.66222l39.90688,1.6916a7.045,7.045,0,0,0,7.26582-5.99632h0a7.045,7.045,0,0,0-5.29021-7.8848l-6.76878-1.65919-26.60384-15.4201c-3.94137,4.0814-9.36875,7.53242-16.00365,10.47124l-.07763,10.84163A7.90644,7.90644,0,0,0,972.06954,749.66222Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <circle cx="775.2764" cy="216.28118" r="21.73271" fill="#ffb8b8" />
        <path
          d="M967.5976,540.7602l-7.1798,13.96072a9.81759,9.81759,0,0,0,5.28347,13.68255h0a9.81759,9.81759,0,0,0,13.162-7.77575l2.99639-20.54668L992.726,488.46587l-.67914-25.80759H970.31419C977.727,481.85325,974.04966,510.16612,967.5976,540.7602Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#ffb8b8"
        />
        <path
          d="M966.91845,465.37487h29.20333l-4.2389-32.02721a26.23474,26.23474,0,0,0-14.77721-20.26711h0Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#f9a826"
        />
        <path
          d="M956.74513,352.21127a20.89527,20.89527,0,0,0-4.56789-16.413c-3.70781-4.3877-9.923-7.979-20.23433-6.28482-22.49732,3.69648-28.98472,22.42528-26.78063,27.13531s8.96,15.62841,8.96,15.62841-4.95831-10.8081.42307-13.00809,26.2072-10.8667,33.476-6.96235a13.02845,13.02845,0,0,1,7.16529,10.97546Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <path
          d="M584.77342,261.76361l-8.73785,4.43367a197.75567,197.75567,0,0,0-39.4403-53.18132c-78.78155-75.73791-204.49207-73.26174-280.23,5.5198s-73.26174,204.49207,5.51981,280.23A198.06115,198.06115,0,0,0,312.786,534.29242l-4.2743,8.81648A207.99067,207.99067,0,1,1,584.77342,261.76361Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#C7C9CF"
        />
        <path
          d="M289.15621,444.05075A69.92355,69.92355,0,0,0,389.9713,540.971l12.03786-12.52162L301.19407,431.52913Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#C7C9CF"
        />
        <path
          d="M294.43926,424.41747a15.60584,15.60584,0,0,0,.43414,22.04056l92.46734,88.895a15.58805,15.58805,0,0,0,21.60642-22.4747l-92.46734-88.895A15.60585,15.60585,0,0,0,294.43926,424.41747Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <path
          d="M571.77387,351.86241l12.03786-12.52162a69.92355,69.92355,0,0,0-100.81509-96.92022l-12.03786,12.52162Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#C7C9CF"
        />
        <path
          d="M463.58664,248.47267a15.60585,15.60585,0,0,0,.43414,22.04056l92.46735,88.895a15.58806,15.58806,0,0,0,21.60642-22.47471l-92.46735-88.895A15.60584,15.60584,0,0,0,463.58664,248.47267Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <path
          d="M502.59309,453.90711v-7.22263s31.517-17.72829,57.12448,0v6.566Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <path
          d="M789.52872,453.90711v-7.22263s31.51695-17.72829,57.12448,0v6.566Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <path
          d="M597.144,453.25051H566.2836s-7.87924-9.19244-34.14336-8.53584-31.517,8.53584-31.517,8.53584-25.93583,17.4-31.84526,39.06789c0,0-31.84525,95.53577-32.50185,170.38854s28.23393,85.35842,43.33581,87.32823,42.0226-.65661,68.28673-66.97353L559.061,644.32205s1.96981-22.98111,25.60752-8.53584,44.649-7.87924,44.649-7.87924h88.64144s29.54714,18.38489,56.46787,3.93962c0,0,10.50565-13.78867,20.3547,32.83016,0,0,30.20375,95.86407,76.82258,86.015s41.366-89.95464,41.366-89.95464-6.23773-122.4565-31.84525-165.79231c0,0-24.62262-44.97733-41.0377-46.94713,0,0-35.45658-6.566-49.24525-.65661l-7.87923,6.566Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#C7C9CF"
        />
        <circle cx="367.21036" cy="369.69685" r="57.12448" fill="#535461" />
        <circle cx="665.30822" cy="369.69685" r="57.12448" fill="#535461" />
        <path
          d="M535.52831,504.85953l-7.6166,6.73675a3.53249,3.53249,0,0,1-4.66844,0l-7.62973-6.73675a3.526,3.526,0,0,1-1.195-2.62641V483.6972a3.53255,3.53255,0,0,1,3.53253-3.526h15.266a3.526,3.526,0,0,1,3.53253,3.51939V502.22A3.52593,3.52593,0,0,1,535.52831,504.85953Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#C7C9CF"
        />
        <path
          d="M535.52831,530.32917l-7.6166-6.73018a3.53252,3.53252,0,0,0-4.66844,0l-7.62973,6.73676a3.526,3.526,0,0,0-1.195,2.62641v18.51622a3.526,3.526,0,0,0,3.51938,3.53253h15.27917a3.526,3.526,0,0,0,3.53253-3.51939V532.96873A3.526,3.526,0,0,0,535.52831,530.32917Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#C7C9CF"
        />
        <path
          d="M512.84268,527.56486l6.73675-7.62973a3.53249,3.53249,0,0,0,0-4.66844l-6.73675-7.62972a3.53252,3.53252,0,0,0-2.62642-1.195H491.70005a3.53255,3.53255,0,0,0-3.526,3.53253v15.266a3.526,3.526,0,0,0,3.51938,3.53253h18.52937A3.526,3.526,0,0,0,512.84268,527.56486Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#C7C9CF"
        />
        <path
          d="M538.3123,527.56486l-6.72361-7.63628a3.526,3.526,0,0,1,0-4.66844l6.73676-7.62973a3.526,3.526,0,0,1,2.62641-1.195h18.51622a3.526,3.526,0,0,1,3.53253,3.51939V525.234a3.526,3.526,0,0,1-3.51939,3.53252H540.95843A3.5259,3.5259,0,0,1,538.3123,527.56486Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#C7C9CF"
        />
        <circle cx="444.68954" cy="432.07416" r="41.366" fill="#535461" />
        <circle cx="587.82904" cy="432.07416" r="41.366" fill="#535461" />
        <circle cx="444.68954" cy="432.07416" r="27.57734" fill="#C7C9CF" />
        <path
          d="M603.05352,599.01632A19.0415,19.0415,0,1,1,622.095,579.97483,19.0632,19.0632,0,0,1,603.05352,599.01632Zm0-36.76978a17.72829,17.72829,0,1,0,17.72829,17.72829A17.7485,17.7485,0,0,0,603.05352,562.24654Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <circle cx="587.82904" cy="432.07416" r="27.57734" fill="#C7C9CF" />
        <path
          d="M746.193,599.01632a19.0415,19.0415,0,1,1,19.04149-19.04149A19.06341,19.06341,0,0,1,746.193,599.01632Zm0-36.76978a17.72829,17.72829,0,1,0,17.72829,17.72829A17.7485,17.7485,0,0,0,746.193,562.24654Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <circle cx="517.57249" cy="436.67038" r="11.81886" fill="#535461" />
        <circle cx="499.50935" cy="397.22167" r="2.58045" fill="#535461" />
        <circle cx="509.17455" cy="397.22167" r="2.58045" fill="#535461" />
        <circle cx="507.8876" cy="409.46731" r="2.58045" fill="#535461" />
        <circle cx="516.91589" cy="409.74965" r="2.62641" fill="#535461" />
        <circle cx="525.93106" cy="409.46731" r="2.58045" fill="#535461" />
        <circle cx="504.02021" cy="403.66952" r="2.58045" fill="#535461" />
        <circle cx="512.31967" cy="403.84022" r="2.62641" fill="#535461" />
        <circle cx="520.85551" cy="403.84022" r="2.62641" fill="#535461" />
        <circle cx="529.79844" cy="403.66952" r="2.58045" fill="#535461" />
        <circle cx="516.91589" cy="397.27419" r="2.62641" fill="#535461" />
        <circle cx="526.10833" cy="397.27419" r="2.62641" fill="#535461" />
        <circle cx="533.66584" cy="397.22167" r="2.58045" fill="#535461" />
        <path
          d="M577.77393,458.84668a4.58609,4.58609,0,0,0-4.58106,4.58105V477.2168a4.58609,4.58609,0,0,0,4.58106,4.58105h3.2832a4.58608,4.58608,0,0,0,4.58105-4.58105V463.42773a4.58608,4.58608,0,0,0-4.58105-4.58105Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <path
          d="M769.502,458.84668a4.58578,4.58578,0,0,0-4.58105,4.58105V477.2168a4.58578,4.58578,0,0,0,4.58105,4.58105h3.28321a4.58578,4.58578,0,0,0,4.58105-4.58105V463.42773a4.58578,4.58578,0,0,0-4.58105-4.58105Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <circle cx="665.96482" cy="334.89688" r="15.75848" fill="#C7C9CF" />
        <circle cx="632.47806" cy="368.38365" r="15.75848" fill="#C7C9CF" />
        <circle cx="700.76479" cy="366.41384" r="15.75848" fill="#C7C9CF" />
        <circle cx="668.59123" cy="401.21381" r="15.75848" fill="#C7C9CF" />
        <path
          d="M607.48535,449.6543a13.29637,13.29637,0,0,0-13.28125,13.28125v58.76562a13.29638,13.29638,0,0,0,13.28125,13.28125H742.417a13.29638,13.29638,0,0,0,13.28125-13.28125V462.93555A13.29637,13.29637,0,0,0,742.417,449.6543Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <path
          d="M835.03451,490.02019H813.66477l10.3165-17.685ZM815.95134,488.707h16.71325l-8.64485-13.832Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <path
          d="M799.70618,524.16355H781.9779V508.40508h17.72828Zm-16.41508-1.3132H798.393V509.71828H783.2911Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <path
          d="M859.12878,524.16355a9.849,9.849,0,1,1,9.849-9.84905A9.86,9.86,0,0,1,859.12878,524.16355Zm0-18.38489a8.53585,8.53585,0,1,0,8.53584,8.53584A8.54552,8.54552,0,0,0,859.12878,505.77866Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#535461"
        />
        <rect
          x="826.13453"
          y="538.66798"
          width="1.31307"
          height="20.89299"
          transform="translate(-304.48492 597.56071) rotate(-45)"
          fill="#535461"
        />
        <rect
          x="816.50615"
          y="548.29363"
          width="20.89814"
          height="1.31339"
          transform="translate(-308.42651 576.08514) rotate(-43.734)"
          fill="#535461"
        />
        <path
          d="M297.93532,299.615c-35.85053,9.88371-75.10832,17.324-89.17508,5.78131,7.70164-12.82157,12.14378-25.459,12.52061-37.86678a21.77156,21.77156,0,0,1,6.40781-15.10265,24.371,24.371,0,0,1,20.32825-6.60564l9.3041,1.30563q.4698.06593.93466.14967A21.75881,21.75881,0,0,1,275.75049,265.612C277.71072,279.06638,284.3025,290.714,297.93532,299.615Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <path
          d="M332.51747,427.25931l13.93731,2.87385a7.32031,7.32031,0,0,1,5.244,10.06732v0a7.32032,7.32032,0,0,1-10.55735,3.33752l-21.31927-13.11231L283.61079,345.2996l18.7556-5.42521Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#a0616a"
        />
        <polygon
          points="74.869 391.06 64.316 394.129 56.328 377.632 64.451 373.399 74.869 391.06"
          fill="#a0616a"
        />
        <polygon
          points="69.507 461.743 56.559 458.315 58.586 436.07 69.729 436.756 69.507 461.743"
          fill="#a0616a"
        />
        <path
          d="M229.22115,523.53935l-13.71372,9.025L162.763,472.90386a11.75556,11.75556,0,0,1,2.7774-17.87756l65.70649-39.26139,10.0638,28.90982-34.21362,32.10679Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <path
          d="M240.77016,554.769q-.05153.067-.10342.13384c-.87644,5.13184-2.41579,9.81264-5.85349,13.20174a10.229,10.229,0,0,1-12.423,5.61061h0a10.229,10.229,0,0,1-7.0935-10.894l2.56258-22.9053c6.14714-1.41311,10.83547-4.90884,13.20847-11.71013L237.042,532.275A15.70525,15.70525,0,0,1,240.77016,554.769Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <circle cx="88.90754" cy="125.70156" r="21.10755" fill="#a0616a" />
        <path
          d="M265.55054,307.34479l-33.27589,27.559L229.683,323.55971l5.80812-8.85353c1.61185-8.68644.5712-18.2653-2.19853-28.42589l21.71018.01995C255.94,294.51108,259.13665,301.674,265.55054,307.34479Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#a0616a"
        />
        <path
          d="M274.919,379.63463l-44.92268,13.684-3.49492-15.97746c-8.4187-11.30366-12.07829-21.621-6.57055-30.03843l-2.77164-12.671c7.95078-6.25994,14.203-12.66487,17.63618-19.31043l27.63927-10.7999,20.31864,1.25018,6,20.1068Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#f9a826"
        />
        <path
          d="M306.83348,342.123a46.28131,46.28131,0,0,0-26.82252,8.21847l2.74233-44.56991h0a16.17559,16.17559,0,0,1,12.7437,9.70148Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#f9a826"
        />
        <path
          d="M229.08063,589.98075l-14.25836-3.509,2.0987-121.153,19.14622-20.96671-4.28583-26.58055-2.7733-29.77709,46.768-11.5967c15.80773,28.7028,25.36231,54.79921,11.41543,71.09995Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <path
          d="M225.80573,630.88621q-.09592.03761-.19209.07475c-4.14021,4.80744-8.68914,8.72734-14.40838,10.10552a12.46567,12.46567,0,0,1-16.49712-1.94914v0a12.46562,12.46562,0,0,1-.56007-15.83257l17.0705-22.30537c7.30641,2.38773,14.39847,1.68417,21.15044-3.92586l3.68052,8.00332A19.13937,19.13937,0,0,1,225.80573,630.88621Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
        <path
          d="M173.71974,441.8318l-12.51508,10.71526a8.12136,8.12136,0,0,0,1.94074,13.57133h0a8.12135,8.12135,0,0,0,10.49869-3.56475l9.05015-16.88,41.5037-75.739-15.60957-2.9342Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#a0616a"
        />
        <path
          d="M226.69874,372.06226c-8.10654-2.44717-15.7-3.88818-21.58919-1.98627,1.23451-15.27631,3.77881-28.92289,12.04965-35.44427l11.75759,1.38135Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#f9a826"
        />
        <path
          d="M270.83475,268.70791l-45.1451-2.77772.55554-9.029q23.63872-15.93927,45.1451,2.77772Z"
          transform="translate(-158.36385 -147.90077)"
          fill="#2f2e41"
        />
      </svg>
    );
  }
}
